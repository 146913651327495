import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import RoadProperties from './RoadProperties'
import EditRelation, { applyToMapSource, resetPaintColor, setPaintColor } from './EditRelation'
import ControlButton from '../../ControlButton'
import { WayModifications } from '../../constants/WayModifications'
import { NetworkColors, RelationColors } from '../../constants/Colors'
import { setEditModeAction } from '../../../actions/defaultActions'
import { patchScenario } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'

const ScenarioLegend = ({ map, logout }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  // Local state
  const [previouslySelectedWayIds, setPreviouslySelectedWayIds] = useState(null)

  const activeStyle = roadPropertyStyles.find(style => style.active)
  const property = RoadProperties[activeStyle.key]
  const unifiedView = activeStyle.key === RoadProperties.unified.key && !editMode.active
  const relationsView = activeStyle.key === RoadProperties.relations.key && !editMode.active
  const relationsEdit = activeStyle.key === RoadProperties.relations.key && editMode.active

  const roadClosureButton = () => {
    return (
      <div>
        <LeftButton>
          <ControlButton
            text="Wegsperrung"
            icon="block"
            onClick={closeWays}
            />
        </LeftButton>
      </div>
    )
  }

  const confirmAndCancelRoadClosureButtons = () => {
    return (
      <div>
        <Description>
          Ausgewählte Elemente: {editMode.wayEdit.modification.selectedWayIds.length}
        </Description>
        { /* Confirm button should be on the right side [BIK-1295] */}
        <LeftButton>
          <ControlButton
            text="Abbrechen"
            onClick={onCancel}
            width='110px' />
        </LeftButton>
        <RightButton>
          <ControlButton
            text="Speichern"
            onClick={onRoadClosureConfirm}
            disabled={editMode.wayEdit.modification.selectedWayIds.length === 0 &&
              previouslySelectedWayIds !== null && previouslySelectedWayIds.length === 0
            }
            width='105px' />
        </RightButton>
      </div>
    )
  }

  const closeWays = () => {
    const source = map.getSource(editMode.scenario.sourceId)
    const closedWayIds = source._data.features
      .filter(f => f.properties.unified === 'closed')
      .map(f => f.properties['@id'])
    setPreviouslySelectedWayIds(closedWayIds)
    setActiveModeAndSelectedWayIds(WayModifications.CloseWays, closedWayIds, true)
    setPaintColor(
      map,
      editMode,
      RelationColors.Remove,
      RelationColors.HoveredRemove,
      NetworkColors.Neutral
    )
  }

  const onRoadClosureConfirm = async () => {
    const selectedWayIds = editMode.wayEdit.modification.selectedWayIds
    const unSelectedWayIds =
      previouslySelectedWayIds.filter(wayId => !selectedWayIds.includes(wayId))
    if (selectedWayIds.length === 0 && unSelectedWayIds.length === 0) {
      onCancel()
    }

    // Send changes to API
    unSelectedWayIds.forEach(async wayId => {
      const changeset = getChangeset(wayId, false)
      await patchScenario(dispatch, defaultErrorHandling, logout, editMode.scenario.id, changeset)
    })
    editMode.wayEdit.modification.selectedWayIds.forEach(async wayId => {
      const changeset = getChangeset(wayId, true)
      await patchScenario(dispatch, defaultErrorHandling, logout, editMode.scenario.id, changeset)
    })

    applyToMapSource(map, editMode, selectedWayIds, unSelectedWayIds)

    // Reset view, like `onCancel()`
    setActiveModeAndSelectedWayIds(WayModifications.SelectWay, [], true)
    resetPaintColor(map, editMode, roadPropertyStyles, RoadProperties.unified.key)
  }

  const getChangeset = (elementId, newClosedState) => {
    const changeset = { 'element-id': 'way/' + elementId }
    if (editMode.active === WayModifications.CloseWays) {
      changeset.closed = newClosedState // To differentiate this from actually removed ways
    }
    return changeset
  }

  const onCancel = () => {
    // Back to default SelectWay modification type and deselect ways
    setActiveModeAndSelectedWayIds(WayModifications.SelectWay, [], false)
    resetPaintColor(map, editMode, roadPropertyStyles, RoadProperties.unified.key)
  }

  /**
   * @param {*} active
   * @param {*} selectedWayIds
   * @param {*} scenarioChanged `true` if the scenario should be set to changed. Defaults to
   * `undefined` to not change the current value (which might already be `true`!).
   */
  const setActiveModeAndSelectedWayIds = (active, selectedWayIds, scenarioChanged = undefined) => {
    const newState = {
      ...editMode,
      active,
      wayEdit: {
        ...editMode.wayEdit,
        modification: {
          ...editMode.wayEdit.modification,
          selectedWayIds
        }
      }
    }
    // Mark scenarioChanged to delete simulation when leaving editMode
    if (scenarioChanged !== undefined) {
      newState.scenarioChanged = scenarioChanged
    }
    dispatch(setEditModeAction(newState, map, editMode))
  }

  return unifiedView || relationsView
    ? null
    : relationsEdit
      ? (
        // Relations edit mode
        <EditRelation map={map} logout={logout} />
        )
      : ( // Default
        <LegendBox>
          <Header>
            { editMode.active === WayModifications.CloseWays ? 'Wegsperrung' : property.label }
          </Header>
          {
            editMode.active === WayModifications.CloseWays
              ? ''
              : property.options.map(option => {
                return <div key={option.value}>
                <LineWrapper>
                  <Stroke height='6px' color={option.color} />
                  <ColorDescription>{option.label}</ColorDescription>
                </LineWrapper>

                {option.label2 !== undefined
                  ? <ColorDescription2>{option.label2}</ColorDescription2>
                  : ''}
              </div>
              })
          }
          { activeStyle.key !== RoadProperties.unified.key
            ? ''
            : editMode.active === WayModifications.SelectWay
              ? roadClosureButton()
              : confirmAndCancelRoadClosureButtons()
          }
        </LegendBox>
        )
}

ScenarioLegend.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

const LegendBox = styled('div')({
  position: 'absolute',
  width: '265px',
  height: '240px',
  backgroundColor: 'white',
  left: '360px',
  bottom: '70px',
  padding: '10px',
  borderRadius: '3px',
  boxShadow: '0 0 0 rgba(0, 0, 0, 0.1)'
})

export const Header = styled('div')({
  fontSize: '15px',
  padding: '10px'
})

const Stroke = styled('div')(({ height, color }) => ({
  height,
  width: '30px',
  backgroundColor: color,
  display: 'inline-block',
  marginRight: '20px'
}))

export const LineWrapper = styled('div')({
  marginLeft: '10px',
  marginTop: '0px'
})

export const ColorDescription = styled('div')({
  display: 'inline-block',
  fontSize: '12px'
})

export const ColorDescription2 = styled('div')({
  display: 'inline-block',
  fontSize: '12px',
  paddingLeft: '60px'
})

export const Description = styled('div')({
  fontSize: '12px',
  marginLeft: '10px',
  marginTop: '15px'
})

const LeftButton = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  position: 'absolute',
  bottom: '20px',
  left: '20px'
})

const RightButton = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: '20px',
  right: '20px'
})

export default ScenarioLegend

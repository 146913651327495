import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import ControlButton from '../../ControlButton'

const AddRelationDialog = ({ open, setOpen, draft, onNameChanged, onSubmit }) => {
  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Neue Radroute erstellen</DialogTitle>
      <DialogContent>
        { /* TODO: Use auto-complete to allow the user to select from relations collection
             This way the user can reference the same relation in another scenario, either
             for created relations or for original relations where all ways where deleted. */}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name der neuen Radroute"
          type="text"
          fullWidth
          variant="outlined"
          value={draft.name}
          onChange={onNameChanged}
        />
        <Typography variant="body2" color="red" sx={{ marginBottom: '20px' }}>
          Nur Buchstaben+Zahlen, keine Sonderzeichen benutzen!
        </Typography>
      </DialogContent>
      <DialogActions sx={{ margin: '0px 15px 10px 15px' }}>
        { /* Confirm button should be on the right side [BIK-1295] */}
        <ControlButton
          width='300px'
          sx={{ margin: '0px' }}
          text='Schließen'
          onClick={closeDialog} />
        <ControlButton
          width='300px'
          sx={{ margin: '0px' }}
          text='Speichern'
          onClick={onSubmit}
        />
        </DialogActions>
      </Dialog>
    </>
  )
}

AddRelationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  draft: PropTypes.object.isRequired,
  onNameChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default AddRelationDialog

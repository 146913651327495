// We use the #-Format to define colors (common and easy to identify).
// Convert the #-Format to rgb() where needed with the converters below.

const lightBlack = '#3C3C3C'
const grey = '#C9C9C9'

const radSimBlue = '#222A35' // dark blue - primary Radsim color
const lightBlue = '#8faadc'
const darkBlue = '#4472c4'

const brightRed = '#FF7043' // bright corral red (contrast to primary color dark blue)
const lightRed = '#ebc3c3'
const red = '#eb0000'
const darkRed = '#d31818'

const orange = '#FFA500'
const yellow = '#FFD700' // gold

const purple = '#800080'

const deepPink = '#FF1493'
const hotPink = '#FF69B4'

const lightGreen = '#c3ebc3'
const green = '#00eb00'
const darkGreen = '#2bc02b'

/**
 * Colors used by the app UI.
 */
export const Colors = {
  Primary: radSimBlue,
  Secondary: brightRed,
  Alert: red,
  AlertBorder: lightRed,
  Unknown: purple
}

export const NetworkColors = {
  Clicked: hotPink,
  Hovered: deepPink,
  Unified: lightBlack,
  Neutral: grey,
  Unknown: purple
}

export const TrafficColors = {
  Neutral: grey,
  LightTraffic: lightBlue,
  HeavyTraffic: darkBlue,
  Unknown: purple
}

export const DifferenceColors = {
  LightPositive: lightGreen,
  Positive: green,
  LightNegative: lightRed,
  Negative: red,
  Neutral: grey,
  Unknown: purple
}

export const EvaluationColors = {
  Extreme: purple,
  StrongNegative: red,
  Negative: orange,
  LightNegative: yellow,
  Optimum: green,
  Neutral: grey,
  Unknown: purple
}

export const RelationColors = {
  Add: darkGreen,
  HoveredAdd: green,
  Remove: darkRed,
  HoveredRemove: red
}

/**
 * Some OSM bicycle relations do not have a `colour` tag, e.g.:
 * https://www.openstreetmap.org/relation/11768498
 *
 * In such a case we use the OSM relation id to always use the same of the specific colors
 * from the following list of good identifyable colors from:
 * https://www.w3schools.com/tags/ref_colornames.asp
 *
 * You can replace colors but if you change the number of colors, relations get new colors.
 */
const GenericRelationColors = [
  '#A52A2A', '#D2691E', '#FF7F50', '#DC143C', '#FF8C00',
  '#DEB887', '#B8860B', '#DAA520', '#5F9EA0', '#6495ED',
  '#00008B', '#4682B4', '#006400', '#556B2F', '#808000',
  '#008080', '#20B2AA', '#8B008B', '#4B0082', '#9370DB',
  '#DA70D6', '#DB7093', '#1E90FF', '#9ACD32', '#FF1493'
]

const commonColorToHex = {
  aliceblue: '#F0F8FF',
  antiquewhite: '#FAEBD7',
  aqua: '#00FFFF',
  aquamarine: '#7FFFD4',
  azure: '#F0FFFF',
  beige: '#F5F5DC',
  bisque: '#FFE4C4',
  black: '#000000',
  blanchedalmond: '#FFEBCD',
  blue: '#0000FF',
  blueviolet: '#8A2BE2',
  brown: '#A52A2A',
  burlywood: '#DEB887',
  cadetblue: '#5F9EA0',
  chartreuse: '#7FFF00',
  chocolate: '#D2691E',
  coral: '#FF7F50',
  cornflowerblue: '#6495ED',
  cornsilk: '#FFF8DC',
  crimson: '#DC143C',
  cyan: '#00FFFF',
  darkblue: '#00008B',
  darkcyan: '#008B8B',
  darkgoldenrod: '#B8860B',
  darkgray: '#A9A9A9',
  darkgreen: '#006400',
  darkkhaki: '#BDB76B',
  darkmagenta: '#8B008B',
  darkolivegreen: '#556B2F',
  darkorange: '#FF8C00',
  darkorchid: '#9932CC',
  darkred: '#8B0000',
  darksalmon: '#E9967A',
  darkseagreen: '#8FBC8F',
  darkslateblue: '#483D8B',
  darkslategray: '#2F4F4F',
  darkturquoise: '#00CED1',
  darkviolet: '#9400D3',
  deeppink: '#FF1493',
  deepskyblue: '#00BFFF',
  dimgray: '#696969',
  dodgerblue: '#1E90FF',
  firebrick: '#B22222',
  floralwhite: '#FFFAF0',
  forestgreen: '#228B22',
  fuchsia: '#FF00FF',
  gainsboro: '#DCDCDC',
  ghostwhite: '#F8F8FF',
  gold: '#FFD700',
  goldenrod: '#DAA520',
  gray: '#808080',
  green: '#008000',
  greenyellow: '#ADFF2F',
  honeydew: '#F0FFF0',
  hotpink: '#FF69B4',
  indianred: '#CD5C5C',
  indigo: '#4B0082',
  ivory: '#FFFFF0',
  khaki: '#F0E68C',
  lavender: '#E6E6FA',
  lavenderblush: '#FFF0F5',
  lawngreen: '#7CFC00',
  lemonchiffon: '#FFFACD',
  lightblue: '#ADD8E6',
  lightcoral: '#F08080',
  lightcyan: '#E0FFFF',
  lightgoldenrodyellow: '#FAFAD2',
  lightgray: '#D3D3D3',
  lightgreen: '#90EE90',
  lightpink: '#FFB6C1',
  lightsalmon: '#FFA07A',
  lightseagreen: '#20B2AA',
  lightskyblue: '#87CEFA',
  lightslategray: '#778899',
  lightsteelblue: '#B0C4DE',
  lightyellow: '#FFFFE0',
  lime: '#00FF00',
  limegreen: '#32CD32',
  linen: '#FAF0E6',
  magenta: '#FF00FF',
  maroon: '#800000',
  mediumaquamarine: '#66CDAA',
  mediumblue: '#0000CD',
  mediumorchid: '#BA55D3',
  mediumpurple: '#9370DB',
  mediumseagreen: '#3CB371',
  mediumslateblue: '#7B68EE',
  mediumspringgreen: '#00FA9A',
  mediumturquoise: '#48D1CC',
  mediumvioletred: '#C71585',
  midnightblue: '#191970',
  mintcream: '#F5FFFA',
  mistyrose: '#FFE4E1',
  moccasin: '#FFE4B5',
  navajowhite: '#FFDEAD',
  navy: '#000080',
  oldlace: '#FDF5E6',
  olive: '#808000',
  olivedrab: '#6B8E23',
  orange: '#FFA500',
  orangered: '#FF4500',
  orchid: '#DA70D6',
  palegoldenrod: '#EEE8AA',
  palegreen: '#98FB98',
  paleturquoise: '#AFEEEE',
  palevioletred: '#DB7093',
  papayawhip: '#FFEFD5',
  peachpuff: '#FFDAB9',
  peru: '#CD853F',
  pink: '#FFC0CB',
  plum: '#DDA0DD',
  powderblue: '#B0E0E6',
  purple: '#800080',
  rebeccapurple: '#663399',
  red: '#FF0000',
  rosybrown: '#BC8F8F',
  royalblue: '#4169E1',
  saddlebrown: '#8B4513',
  salmon: '#FA8072',
  sandybrown: '#F4A460',
  seagreen: '#2E8B57',
  seashell: '#FFF5EE',
  sienna: '#A0522D',
  silver: '#C0C0C0',
  skyblue: '#87CEEB',
  slateblue: '#6A5ACD',
  slategray: '#708090',
  snow: '#FFFAFA',
  springgreen: '#00FF7F',
  steelblue: '#4682B4',
  tan: '#D2B48C',
  teal: '#008080',
  thistle: '#D8BFD8',
  tomato: '#FF6347',
  turquoise: '#40E0D0',
  violet: '#EE82EE',
  wheat: '#F5DEB3',
  white: '#FFFFFF',
  whitesmoke: '#F5F5F5',
  yellow: '#FFCC00', // Original yellow is hard to see on a white map: '#FFFF00'
  yellowgreen: '#9ACD32'
}

export const hexStringToRgbObject = (hex) => {
  const bigint = parseInt(hex.substring(1), 16)
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255
  }
}

const isHexColor = (color) => /^#[0-9A-Fa-f]{6}$/.test(color)

const isCommonColor = (color) => color.toLowerCase() in commonColorToHex

/**
 * Tried to interpret the color from the OSM relation tags.
 *
 * See https://www.w3schools.com/tags/ref_colornames.asp
 *
 * @param {*} relation The relation to get the color from.
 * @returns The color of the relation.
 */
const hexColorFromRelation = (relation) => {
  if (!('tags' in relation)) {
    throw new Error('Relation has no tags.')
  }
  if (!('colour' in relation.tags)) {
    return generateColorFromId(relation.osmId)
  }
  if (isHexColor(relation.tags.colour)) {
    return relation.tags.colour
  }
  if (isCommonColor(relation.tags.colour)) {
    return commonColorToHex[relation.tags.colour]
  }

  console.log('Unknown color: ' + relation.tags.colour)
  if (!isHexColor(NetworkColors.Unknown)) {
    throw new Error('Unknown color is not a hex color.')
  } else {
    return NetworkColors.Unknown
  }
}

const generateColorFromId = (osmId) => {
  const hash = hashString(osmId.toString())
  const colorIndex = Math.abs(hash) % GenericRelationColors.length
  return GenericRelationColors[colorIndex]
}

const hashString = (string) => {
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = ((hash << 5) - hash) + string.charCodeAt(i)
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

// Converts RGB to hex
const rgbToHex = (r, g, b) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
}

/**
 * Checks `colour` tag in the networkGeometry ways' `relations` and adds:
 * - an average `relationColor` for all relations
 * - a `tags.colour` for each relation (overwriting the original `colour` tag)
 * - we don't add `relation0Color` as this highlights the hovered relation in different
 *   colors as some ways have another relation on position 0 then the hovered relation
 *
 * @param {*} networkGeometry The ways to calculate the `relationColor` for.
 * @returns The networkGeometry with the added `relationColor`.
 */
export const addRelationColor = (networkGeometry) => {
  const updatedFeatures = networkGeometry.features.map(way => {
    const properties = { ...way.properties }

    // If the way has no relations, use the default color
    if (!('relations' in properties)) {
      properties.relationColor = NetworkColors.Neutral
      return { ...way, properties }
    }

    // If the feature has only one color, use this color
    if (properties.relations.length === 1) {
      const relation = { ...properties.relations[0] }
      const color = hexColorFromRelation(relation)
      relation.tags = { ...relation.tags, colour: color }
      properties.relationColor = color
      return { ...way, properties, relations: [relation] }
    }

    // If the feature has multiple colors, calculate the average color
    let totalR = 0; let totalG = 0; let totalB = 0; let count = 0
    const updatedRelations = properties.relations.map(relation => {
      const newRelation = { ...relation }
      const color = hexColorFromRelation(newRelation)
      newRelation.tags = { ...newRelation.tags, colour: color }

      const rgbColor = hexStringToRgbObject(color)
      totalR += rgbColor.r
      totalG += rgbColor.g
      totalB += rgbColor.b
      count++

      return newRelation
    })

    const avgR = Math.round(totalR / count)
    const avgG = Math.round(totalG / count)
    const avgB = Math.round(totalB / count)

    properties.relationColor = rgbToHex(avgR, avgG, avgB)
    properties.relations = updatedRelations

    return { ...way, properties }
  })

  return { ...networkGeometry, features: updatedFeatures }
}

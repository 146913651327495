import { debug, supportEmail, ERROR_MESSAGE_TOKEN_INACTIVE, errorTrackingAccepted } from './login/utils.js'
import { showSnackbar } from './SnackbarService.js'
import * as Sentry from '@sentry/react'

/**
 * Commonly used functions helping with job execution process.
 */

/**
  * Default handler for unsuccessful HTTP responses.
  *
  * @param {object} error The error to be handled.
  */
export const defaultErrorHandling = async (error, logout, snackbarMessage = '') => {
  if (errorTrackingAccepted()) {
    Sentry.captureException(error)
  }
  if (debug()) console.log(error)

  // Unauthorized
  const prefix = snackbarMessage !== '' ? snackbarMessage : 'Daten konnten nicht geladen werden'
  if (error.response !== undefined && error.response.status === 401) {
    showSnackbar(prefix + '. Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')
    logout()
  } else {
    if (error.message === ERROR_MESSAGE_TOKEN_INACTIVE) {
      showSnackbar(prefix + '. Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')
      logout()
    } else {
      const details = error.response !== undefined ? error.response.status : 'undefined'
      showSnackbar(prefix + ' (Fehler ' + details + '). ' +
        'Bitte kontaktieren Sie ' + supportEmail() + ' falls der Fehler wiederholt auftritt.')
    }
  }
}

/**
  * Default handler for final Websocket events like close or error.
  *
  * @param {object} error The error to be handled.
  */
export const defaultWebsocketErrorHandling = async (
  statusCode,
  logout,
  snackbarMessage = '',
  softErrorHandler = () => {} // default: does nothing
) => {
  if (errorTrackingAccepted()) {
    Sentry.captureMessage('Websocket error: ' + statusCode)
  }
  if (debug()) console.log('Websocket error, code: ' + statusCode)

  // Unauthorized
  const prefix =
    snackbarMessage !== '' ? snackbarMessage : 'Fortschritt konnte nicht geladen werden'
  if (statusCode === 3000) {
    showSnackbar(
      prefix + '. Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.'
    )
    logout()
  } else {
    showSnackbar(
      prefix + ' (Fehler ' + statusCode + '). ' +
      'Bitte kontaktieren Sie ' + supportEmail() + ' falls der Fehler wiederholt auftritt.'
    )
    softErrorHandler()
  }
}

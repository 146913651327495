import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { EvaluationColors } from '../constants/Colors'

const EvaluationLegend = ({ evaluationThresholds }) => {
  return (
    <LegendBox>
      <Header>Faktor: Soll-/Ist-VM</Header>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.Extreme} />
        <Description>&le; {evaluationThresholds[1]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.StrongNegative} />
        <Description>
          {evaluationThresholds[1]} - {evaluationThresholds[2]}
        </Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.Negative} />
        <Description>
          {evaluationThresholds[2]} - {evaluationThresholds[3]}
        </Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.LightNegative} />
        <Description>
          {evaluationThresholds[3]} - {evaluationThresholds[4]}
        </Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.Optimum} />
        <Description>{evaluationThresholds[4]}</Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.LightNegative} />
        <Description>
          {evaluationThresholds[4]} - {evaluationThresholds[5]}
        </Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.Negative} />
        <Description>
          {evaluationThresholds[5]} - {evaluationThresholds[6]}
        </Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.StrongNegative} />
        <Description>
          {evaluationThresholds[6]} - {evaluationThresholds[7]}
        </Description>
      </LineWrapper>
      <LineWrapper>
        <Line height="8px" color={EvaluationColors.Extreme} />
        <Description>&ge; {evaluationThresholds[7]}</Description>
      </LineWrapper>

      <p style={{ fontStyle: 'italic', marginTop: '10px' }}>
        Verkehrsmenge (Tooltip): Soll-VM - Ist-VM
      </p>
    </LegendBox>
  )
}

EvaluationLegend.propTypes = {
  evaluationThresholds: PropTypes.arrayOf(PropTypes.number)
}

const LegendBox = styled('div')({
  position: 'absolute',
  width: '180px',
  height: '310px',
  backgroundColor: 'white',
  left: '360px',
  bottom: '70px',
  padding: '10px',
  borderRadius: '3px',
  boxShadow: '0 0 0 rgba(0, 0, 0, 0.1)'
})

const Header = styled('div')({
  fontSize: '15px',
  padding: '10px'
})

const Line = styled('div')(({ height, color }) => ({
  height,
  width: '30px',
  backgroundColor: color,
  display: 'inline-block',
  marginRight: '20px'
}))

const LineWrapper = styled('div')({
  marginLeft: '10px',
  marginTop: '0px'
})

const Description = styled('div')({
  display: 'inline-block',
  fontSize: '12px'
})

export default EvaluationLegend

import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import ControlButton from '../../ControlButton'
import { RelationModifications } from '../../constants/RelationModifications'
import { Colors } from '../../constants/Colors'

/**
 * Presentational component for the box of the edit relation mode.
 *
 * @param {*} modificationMode A string from `WayModification` or `RelationModification`.
 * @param {*} relationEdit The relation that is currently being edited.
 * @param {*} onAdd Callback for adding a way to the relation.
 * @param {*} onRemove Callback for removing a way from the relation.
 * @param {*} onSave Callback for saving the changes to the relation.
 * @param {*} onCancel Callback for cancelling the edit mode.
 * @param {*} onClose Callback for unselecting the relation to edit.
 * @param {*} onCreate Callback for switching to relation creation mode.
 * @param {*} onDelete Callback for deleting the relation.
 * @param {*} onDeleteCancel Callback for cancelling the delete attempt.
 * @param {*} onDeleteConfirm Callback for confirming the delete attempt.
 * @returns The legend for the edit relation mode.
 */
const EditRelationBox = ({
  modificationMode,
  relationEdit,
  onAdd,
  onRemove,
  onSave,
  onCancel,
  onClose,
  onCreate,
  onDelete,
  onDeleteCancel,
  onDeleteConfirm
}) => {
  const addAndRemoveWaysButtons = (showRemove) => {
    return (
      <div>
        <LeftButton>
          <ControlButton
            text="Weg hinzufügen"
            onClick={onAdd}
            width='148px' />
        </LeftButton>
        {showRemove
          ? (
          <RightButton>
            <ControlButton
              text="Weg entfernen"
              onClick={onRemove}
              width='142px' />
          </RightButton>
            )
          : ''}
      </div>
    )
  }

  const deleteRouteButtons = () => {
    return (
      <div>
        <LeftButton>
        <ControlButton
          text="Route Löschen"
          onClick={onDeleteConfirm}
          backgroundColor={Colors.Alert}
          color={'white'}
          width='160px' />
        </LeftButton>
        <RightButton>
          <ControlButton
            text="Abbrechen"
            onClick={onDeleteCancel}
            width='130px' />
        </RightButton>
      </div>
    )
  }

  const confirmChangedWaysButtons = () => {
    return (
      <div>
        <Description>
          Ausgewählte Elemente: {relationEdit.modification.selectedWayIds.length}
        </Description>
        { /* Confirm button should be on the right side [BIK-1295] */}
        <LeftButton>
          <ControlButton
            text="Abbrechen"
            onClick={onCancel}
            width='142px' />
        </LeftButton>
        <RightButton>
          <ControlButton
            text="Speichern"
            disabled={relationEdit.modification.selectedWayIds.length === 0}
            onClick={onSave}
            width='148px' />
        </RightButton>
      </div>
    )
  }

  return (
    <LegendBox>
      { relationEdit.clicked.relationId
        ? (
          <div>
            <Header>{ relationEdit.clicked.tags.name }</Header>

            {
              modificationMode === RelationModifications.EditRelation
                ? (<RemoveButton>
                  <ControlButton icon="delete" tooltip="Radroute löschen" onClick={onDelete} />
                </RemoveButton>)
                : ''
            }

            {
              modificationMode === RelationModifications.CreateRelation ||
              modificationMode === RelationModifications.EditRelation
                ? (<CloseButton>
                  <ControlButton icon="close" tooltip="Auswahl verweerfen" onClick={onClose} />
                </CloseButton>)
                : ''
            }

            {
              modificationMode === RelationModifications.CreateRelation
                ? <Description>Füge Sie Wege hinzu um die Route zu erstellen</Description>
                : <Description>Support-Id: { relationEdit.clicked.relationId }</Description>
            }

            {
              modificationMode === RelationModifications.EditRelation
                ? addAndRemoveWaysButtons(true)
                : modificationMode === RelationModifications.CreateRelation
                  ? addAndRemoveWaysButtons(false)
                  : modificationMode === RelationModifications.DeleteRelation
                    ? deleteRouteButtons()
                    : modificationMode === RelationModifications.AddWays ||
                      modificationMode === RelationModifications.AddWaysToNewRelation ||
                      modificationMode === RelationModifications.RemoveWays
                      ? confirmChangedWaysButtons()
                      : 'Unexpected mode: ' + modificationMode
            }
          </div>
          )
        : (
          <div>
            <Header>
              Wählen Sie eine Radroute auf der Karte aus oder erstellen Sie eine neue Radroute.
            </Header>
            <LeftButton>
              <ControlButton
                icon="add"
                tooltip="Radroute erstellen"
                text="Radroute erstellen"
                onClick={onCreate}
              />
            </LeftButton>
          </div>
          )
        }
    </LegendBox>
  )
}

EditRelationBox.propTypes = {
  modificationMode: PropTypes.string.isRequired,
  relationEdit: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteCancel: PropTypes.func.isRequired,
  onDeleteConfirm: PropTypes.func.isRequired
}

const LegendBox = styled('div')({
  position: 'absolute',
  width: '340px',
  height: '200px',
  backgroundColor: 'white',
  left: '360px',
  bottom: '70px',
  padding: '20px',
  borderRadius: '3px',
  boxShadow: '0 0 0 rgba(0, 0, 0, 0.1)'
})

export const Header = styled('div')({
  fontSize: '15px',
  marginBottom: '10px'
})

const RemoveButton = styled('div')({
  position: 'absolute',
  top: '10px',
  right: '30px'
})

const CloseButton = styled('div')({
  position: 'absolute',
  top: '10px',
  right: '0px'
})

export const Description = styled('div')({
  fontSize: '12px',
  marginBottom: '10px'
})

const LeftButton = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  position: 'absolute',
  bottom: '20px',
  left: '20px'
})

const RightButton = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: '20px',
  right: '20px'
})

export default EditRelationBox

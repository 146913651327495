import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setRoadPropertyStyleActiveAction } from '../../../actions/defaultActions.js'
import { useSnackbarContext } from '../../SnackbarContext.js'
import RoadProperties from './RoadProperties.js'
import usePaintPopupHandlers from '../../map/usePaintPopupHandlers.js'
import { styled } from '@mui/material'
import { WayModifications } from '../../constants/WayModifications.js'
import { RelationModifications } from '../../constants/RelationModifications.js'

export const isEditingRoute = (active) => {
  return active === RelationModifications.AddWays ||
    active === RelationModifications.AddWaysToNewRelation ||
    active === RelationModifications.CreateRelation ||
    active === RelationModifications.DeleteRelation ||
    active === RelationModifications.RemoveWays ||
    // The following should normally be automatically de-selected by
    // `defaultActions.setRoadPropertyStyleActiveAction` but throws
    // "this.expression.evaluate is not a function" from Mapbox.
    // Until this is fixed, we just ask the user to unselect the relation.
    // We tried to dispatch the same action as
    // `defaultActions.setRoadPropertyStyleActiveAction` even with a delay to
    // ensure there is no race-condition, but this does not fix the error, see 3 lines below
    // Thus, we just ask the user to leave the relation selection manually.
    active === RelationModifications.EditRelation
}

export const isClosingWay = (active) => {
  return active === WayModifications.CloseWays
}

const PaintSwitcher = ({ map }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbarContext()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  const { enterPopupMode, exitPopupMode } = usePaintPopupHandlers(map)

  const setStyle = async (style) => {
    if (isEditingRoute(editMode.active)) {
      enqueueSnackbar(
        'Beenden und schließen Sie zuerst die Routenbearbeitung',
        { autoHideDuration: 3000 }
      )
      return
    }/* else if (editMode.active === RelationModifications.EditRelation) {
      unselectRelation()
      console.log('unselecting relation ...')
      await sleep(3000)
    } */ // The clicked relations are unclicked by `setRoadPropertyStyleActiveAction` below

    if (isClosingWay(editMode.active)) {
      enqueueSnackbar('Beenden Sie zuerst die Wegsperrung', { autoHideDuration: 3000 })
      return
    }

    // Adjust tooltips:
    if (style.key === RoadProperties.relations.key) {
      enterPopupMode(map, visibleLayerId, 'relation.tags.name', '') // no label: 1+ relation names
    } else {
      exitPopupMode(visibleLayerId)
    }

    // Adjust layer paint style
    map.setPaintProperty(visibleLayerId, 'line-width', style.lineWidth)
    map.setPaintProperty(visibleLayerId, 'line-color', style.colors)
    map.setPaintProperty(visibleLayerId, 'line-opacity', style.opacity)
    map.setPaintProperty(visibleLayerId, 'line-dasharray', style.lineDashArray)

    // Adjust layer layout style
    map.setLayoutProperty(visibleLayerId, 'line-sort-key', style.lineSortKey) // FIXME

    // The action also unsets the clicked relation internally
    const previouslyActiveKey = roadPropertyStyles.find(s => s.active).key
    dispatch(
      setRoadPropertyStyleActiveAction(
        style.key,
        map,
        previouslyActiveKey,
        editMode,
        dispatch,
        roadPropertyStyles
      )
    )
  }

  /* const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  const unselectRelation = () => {
    const newEditMode = {
      ...editMode,
      // reset from RelationModifications.EditRelation
      active: RelationModifications.SelectRelation,
      relationEdit: {
        ...editMode.relationEdit,
        clicked: { relationId: null, wayIds: [], tags: null }
      }
    }
    dispatch(setEditModeAction(newEditMode, map, editMode))
    return newEditMode
  } */

  return <LayerSwitcher>
    <Title>Anzeige</Title>
    {roadPropertyStyles.map((style) => (
      <a
        href="#"
        key={style.key}
        className={style.active ? 'active' : ''}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation() // is this needed?
          setStyle(style)
        }}
      >
        {style.label}
      </a>
    ))}
  </LayerSwitcher>
}

const Title = styled('div')({
  fontSize: '15pt',
  color: '#222A35',
  paddingBottom: '10px'
})

PaintSwitcher.propTypes = {
  map: PropTypes.object // may be null until map is intialized
}

const LayerSwitcher = styled('div')({
  border: '1px solid lightgrey',
  width: '94%',
  margin: '10px',
  padding: '10px',
  '& a': {
    display: 'block',
    padding: '5px 10px',
    color: '#333',
    textDecoration: 'none'
  },
  '& a.active': {
    backgroundColor: '#0074d9',
    color: '#fff'
  }
})

export default PaintSwitcher

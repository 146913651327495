import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Sidebar from './sidebar/Sidebar'
import Footer from './Footer'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'
import cloneDeep from 'lodash.clonedeep'
import { useSelector, useDispatch } from 'react-redux'
import Header from './header/Header'
import { autoLogin, getLocalStorage, setLocalStorage, mockAuth, getConfig, errorTrackingAccepted } from './login/utils'
import LogoutButton from './header/LogoutButton'
import { LocalStorage } from './constants/LocalStorage'
import { MapLayers } from './constants/MapLayers'
import * as Sentry from '@sentry/browser'
import MapContainer from './map/MapContainer'
import { addRelationColor, DifferenceColors, EvaluationColors, TrafficColors } from './constants/Colors'
import ScenarioView from './sidebar/scenario/ScenarioView'
import TrafficMapsView from './sidebar/trafficMaps/TrafficMapsView'
import DifferenceMapsView from './sidebar/differenceMaps/DifferenceMapsView'
import { getLineSortKey, getLineWidth } from './map/MapBoxHelpers'
import mapboxgl from 'mapbox-gl'
import AttributeSwitcher from './sidebar/scenario/AttributeSwitcher'
import { differenceLayerPrefix, evaluationLayerPrefix, trafficLayerPrefix } from './IdHelper'

const Dashboard = ({ accessToken }) => {
  // Stateless Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate() // To forward the user, e.g. on auth error

  // TODO: check if roadPropertyStyles and traffic-/differenceThresholds change regulary
  // as this re-renders to all children components. See RFR-1132.

  // Redux state
  const roadPropertyStyles = useSelector(state => state.roadPropertyStyles)

  // Local state - DO NOT ADD states which change regularly (and trigger children re-rendering)
  const [map, setMap] = useState(null) // only changes on viewport re-sizing
  const [width, setWidth] = useState(0) // is only set once
  const [trafficThresholds, setTrafficThresholds] = useState(null) // TODO: move out of Dashboard?
  const [differenceThresholds, setDifferenceThresholds] = useState(null) // TODO: move out?
  const [evaluationThresholds, setEvaluationThresholds] = useState(null) // TODO: move out?

  /**
   * Effects which depend on no state/prop (i.e. only executed on un-/mount).
   *
   * The first part is called when the component is inserted into the DOM.
   * The returned function is called when the component is removed from the DOM.
   *
   * Checks login and loads and sets the API-QualitySegment data asynchronously.
   */
  useEffect(() => {
    // Required as it's not allowed to useEffect(async ()...
    // We cannot blockingly wait for `autoLogin` in `useEffect` but when the token is inactive we
    // don't want `MapContainer.loadData()` to fire and show errors. Unfortunately, we werent able
    // to fix this using the response from `await autoLogin`. [BIK-1083]
    // Using `async` in `useEffect` as in the other `web-app` also does not fix this.
    const initialize = async () => {
      // Check if user is logged in otherwise redirect to Login Page
      if (!await autoLogin(navigate, '/map', '/', dispatch, logout)) {
        return
      }

      if (errorTrackingAccepted()) {
        initializeSentry()
      }
    }

    /**
     * Update internal viewport size state
     */
    function updateWindowDimensions () {
      setWidth(window.innerWidth)
    }

    // Register to viewport size changes
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    initialize() // async stuff

    // Cleanup (component did unmount)
    return () => {
      // Unregister to viewport size changes
      window.removeEventListener('resize', updateWindowDimensions)
    }
    // eslint-disable-next-line
  }, []) // effect depends on no state/props: only run on un-/mount, not re-render

  /**
   * Initialize Sentry error tracking. Should happen as early as possible in the lifecycle.
   * See https://docs.sentry.io/platforms/javascript/guides/react/.
   *
   * This reports any uncaught exceptions triggered by our app to Sentry.
   *
   * Further configuration is possible, like filtering event data forwarded to sentry:
   * https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
   */
  const initializeSentry = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT
    // if (isProductionEnvironment() /* && process.env.REACT_APP_SENTRY_RELEASE */) {
    Sentry.init({
      // Sentry DSN. This ids the account but is not a secret (public key).
      dsn: 'https://ae30ce3e3771c073fa0b69ac90bf0aff@o418976.ingest.us.sentry.io/4506768609705984',

      // For more integrations like router, redux, etc. see
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/
      integrations: [Sentry.browserTracingIntegration()],

      // Should always be `production`. Sentry also adds the `url` to identify e.g. staging.
      environment,

      // This release name is also used in `sentry.js` to upload the source-map
      release: process.env.REACT_APP_SENTRY_RELEASE,

      // Reduce sample rates if we get close to free tier (50T errors, 100T transactions).
      // Captures 100% of errors
      sampleRate: 1.0,
      // Captures 0-100% of transactions for performance tracing.
      // Performance tracing is only enabled on staging right now.
      tracesSampler: () => {
        if (window.location.host === 'staging.radsim.de') {
          return 1.0
        } else {
          return 0.0
        }
      }
    })
  }

  /**
   * Handler for on-logout clicks by the user.
   *
   * `LoginForm.js` contains a copy of this function and should be kept in sync.
   */
  const logout = useCallback(() => {
    // Clear local storage
    const errorTrackingAccepted = getLocalStorage(LocalStorage.ErrorTrackingAccepted)
    const configuration = getLocalStorage(LocalStorage.AuthServiceConfiguration)
    const endSessionUrl = configuration.endSessionEndpoint
    const idToken = getLocalStorage(LocalStorage.IdToken)
    localStorage.clear()
    // Set terms-accepted flag so the used does not have to accept it again on the next login
    setLocalStorage(LocalStorage.TermsAccepted, true)
    setLocalStorage(LocalStorage.ErrorTrackingAccepted, errorTrackingAccepted)

    // Inform the auth server that the user wants to end the session
    if (mockAuth()) {
      console.log('Mocking logout ...')
      window.location.href = '/'
    } else {
      const redirectUri = getConfig().redirectUri
      const u = `${endSessionUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`
      window.location.href = u
      // The auth server will then redirect the user to `Callback` which navigates the user to `/`
    }

    // Prevent default handling of the link-click
    return false
  }, [])

  // This threshold is coded into the Sidenav button, so far we were not able to overwrite it
  const mobileView = width < 993

  /**
   * Adds a new layer with features to the map.
   *
   * @param {*} layerId the id to be used to identify the feature layer in the map.
   * @param {*} data the data to add to the map
   * @param {*} layerType one of a pre-defined set of layer types. See @code: MapLayers
   */
  const addFeature = (map, layerId, data, layerType) => {
    validateInputs(layerId, layerType)

    if (layerType === MapLayers.Scenario) {
      data = addRelationColor(data)
    }

    const thresholds = calculateThresholds(layerType, data)
    applyThresholds(layerType, thresholds)

    const dynamicWidth = getDynamicWidth(layerType, thresholds)
    const lineWidth = getLineWidth(dynamicWidth, false, false)
    const showTrafficOnHover = layerType !== MapLayers.Scenario
    const colors = getColors(layerType, thresholds)
    const lineDashArray = getLineDashArray(layerType)
    const lineSortKey = getLineSortKey(layerType)

    // Deep copy or else networkGeometry changes influence all networks created from this template
    const deepDataCopy = cloneDeep(data)
    addSourceToMap(map, layerId, deepDataCopy)
    addLayerToMap(map, layerId, lineWidth, colors, lineDashArray, lineSortKey)

    if (showTrafficOnHover) {
      // We use the simple hover popup version which does not need to to store the handler
      // reference as the handlers are removed by switching the layer. The other version is
      // in `usePaintPopupHandlers` hook.
      addHoverPopup(map, layerId)
    }
  }

  const validateInputs = (layerId, layerType) => {
    if (!layerId) {
      throw Error('Unexcepted layerId: ' + layerId)
    }
    if (
      ![
        MapLayers.Scenario,
        MapLayers.TrafficMap,
        MapLayers.DifferenceMap,
        MapLayers.Evaluation
      ].includes(layerType)
    ) {
      throw Error('Unknown layerType: ' + layerType)
    }
  }

  const calculateThresholds = (layerType, data) => {
    if (layerType === MapLayers.TrafficMap) {
      const maxTrafficValue = data.features.reduce((max, feature) => {
        return Math.max(max, feature.properties.traffic)
      }, -Infinity)

      return calculateTrafficThresholds(maxTrafficValue)
    }
    if (layerType === MapLayers.DifferenceMap) {
      return calculateDifferenceThresholds()
    }
    if (layerType === MapLayers.Evaluation) {
      return generateEvaluationThresholds()
    }
    return null
  }

  const applyThresholds = (layerType, thresholds) => {
    if (layerType === MapLayers.TrafficMap) {
      setTrafficThresholds(thresholds)
    }
    if (layerType === MapLayers.DifferenceMap) {
      setDifferenceThresholds(thresholds)
    }
    if (layerType === MapLayers.Evaluation) {
      setEvaluationThresholds(thresholds)
    }
  }

  /**
   * Returns the dynamic line width for the given layer type or null if the line width of the
   * feature should not be dependent on the feature's traffic property.
   *
   * @param {*} layerType The layer type to apply
   * @param {*} thresholds The thresholds to apply
   * @returns The map style for the layer type or null if the line with should be static
   */
  const getDynamicWidth = (layerType, thresholds) => {
    if (layerType === MapLayers.Scenario) return null
    if (layerType === MapLayers.TrafficMap) return trafficLineWidth(thresholds)
    if (layerType === MapLayers.DifferenceMap || layerType === MapLayers.Evaluation) {
      return differenceLineWidth(thresholds)
    }
  }

  /**
   * Determines the colors to be used based on the layer type.
   *
   * @param {*} layerType The layer type to apply
   * @param {*} thresholds The thresholds to apply
   * @returns The colors to be used
   */
  const getColors = (layerType, thresholds) => {
    if (layerType === MapLayers.Scenario) {
      return roadPropertyStyles.filter(s => s.active)[0].colors
    }
    if (layerType === MapLayers.TrafficMap) {
      const trafficThreshold = getThreshold(thresholds, 1, 0)
      return createTrafficColors(trafficThreshold)
    }
    if (layerType === MapLayers.DifferenceMap) {
      const diffThreshold = getThreshold(thresholds, 0, 0)
      return createDiffColors(diffThreshold)
    }
    if (layerType === MapLayers.Evaluation) {
      return createEvaluationColors(thresholds)
    }
    throw Error('Unknown layerType: ' + layerType)
  }

  const getLineDashArray = (layerType) => {
    if (layerType === MapLayers.Scenario) {
      return roadPropertyStyles.filter(s => s.active)[0].lineDashArray
    }
    return [1, 0] // no dashes
  }

  const addSourceToMap = (map, layerId, data) => {
    map.addSource(layerId, {
      type: 'geojson',
      data,
      promoteId: '@id' // Required to access all features via `setFeatureState` in Scenarios
    })
  }

  const addLayerToMap = (map, layerId, lineWidth, colors, lineDashArray, lineSortKey) => {
    map.addLayer({
      id: layerId,
      type: 'line',
      source: layerId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
        'line-sort-key': lineSortKey, // Z-Index of the lines on the map
        visibility: 'none' // visibility handled by reducer
      },
      paint: layerPaint(lineWidth, colors, lineDashArray)
    })
  }

  /**
   * The properties used to display features on the map (line width, color).
   *
   * @param {*} lineWidth The line width to be used for the features.
   * @param {*} colors The colors to be used for the features.
   * @param {*} lineDashArray The line dash array to be used for the features
   */
  const layerPaint = (lineWidth, colors, lineDashArray) => {
    return {
      'line-width': lineWidth,
      'line-color': colors,
      'line-dasharray': lineDashArray
    }
  }

  const getThreshold = (thresholds, index, defaultValue) => {
    return thresholds && thresholds[index] !== undefined ? thresholds[index] : defaultValue
  }

  const createDiffColors = (threshold) => {
    return [
      'case',
      ['<', ['get', 'traffic'], -threshold], // < -threshold
      DifferenceColors.Negative,
      ['<', ['get', 'traffic'], 0], // -1 ... -threshold
      DifferenceColors.Negative,
      ['>', ['get', 'traffic'], threshold], // > threshold
      DifferenceColors.Positive,
      ['>', ['get', 'traffic'], 0], // 1 ... threshold
      DifferenceColors.Positive,
      DifferenceColors.Neutral
    ]
  }

  const createEvaluationColors = (thresholds) => {
    console.log('thresholds: ', thresholds)
    const ret = [
      'case',
      ['==', ['get', 'trafficFactor'], thresholds[0]], // = 0 (missing data)
      EvaluationColors.Neutral,
      ['<=', ['get', 'trafficFactor'], thresholds[1]], // < 1/8
      EvaluationColors.Extreme,
      ['<', ['get', 'trafficFactor'], thresholds[2]], // < 1/4
      EvaluationColors.StrongNegative,
      ['<', ['get', 'trafficFactor'], thresholds[3]], // < 1/2
      EvaluationColors.Negative,
      ['<', ['get', 'trafficFactor'], thresholds[4]], // < 1
      EvaluationColors.LightNegative,
      ['==', ['get', 'trafficFactor'], thresholds[4]], // = 1 (exact match)
      EvaluationColors.Optimum,
      ['<', ['get', 'trafficFactor'], thresholds[5]], // < 2
      EvaluationColors.LightNegative,
      ['<', ['get', 'trafficFactor'], thresholds[6]], // < 4
      EvaluationColors.Negative,
      ['<', ['get', 'trafficFactor'], thresholds[7]], // < 8
      EvaluationColors.StrongNegative,
      ['>=', ['get', 'trafficFactor'], thresholds[7]], // >= 8
      EvaluationColors.Extreme,
      DifferenceColors.Unknown // Default case
    ]
    return ret
  }

  const createTrafficColors = (threshold) => {
    return [
      'case',
      ['<', ['get', 'traffic'], threshold],
      TrafficColors.LightTraffic,
      ['>', ['get', 'traffic'], threshold - 1],
      TrafficColors.HeavyTraffic,
      TrafficColors.Neutral
    ]
  }

  const addHoverPopup = (map, layerId) => {
    // create mapbox popup
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      closeOnMove: true
    })
    // add popup on hover
    /*
       use mousemove here, because with mouseenter the segment where
       the cursor enters the segment stays marked until the cursor leaves the layer
    */
    map.on('mousemove', layerId, (e) => {
      map.getCanvas().style.cursor = 'pointer'

      const lngLat = e.lngLat
      const value = e.features[0].properties.traffic
      popup.setLngLat(lngLat).setHTML('Verkehrsmenge: ' + value).addTo(map)
    })
    map.on('mouseleave', layerId, () => {
      popup.remove()
      map.getCanvas().style.cursor = ''
    })
  }

  const calculateTrafficThresholds = (maxValue) => {
    // "1": BIK-1237: Hide segments with 0 traffic (= less then 1)
    if (maxValue < 500) {
      return [
        1, 50, 100, 150, 200, 250, 300, 350, 400, 450
      ]
    } else if (maxValue <= 1000) {
      return [
        1, 100, 200, 300, 400, 500, 600, 700, 800, 900
      ]
    } else {
      return [
        1, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800
      ]
    }
  }

  const calculateDifferenceThresholds = () => {
    const highestThreshold = 250 // was 500
    return [
      highestThreshold / 5 * 1, // 50 was 100
      highestThreshold / 5 * 2, // 100 was 200
      highestThreshold / 5 * 3, // 150 was 300
      highestThreshold / 5 * 4, // 200 was 400
      highestThreshold // 250 was 500
    ]
  }

  const generateEvaluationThresholds = () => {
    return [
      0, // 0: Missing data
      0.125, // 1/8
      0.25, // 1/4
      0.5, // 1/2
      1, // 1: Exact match (neutral, Soll = Ist)
      2, // 2
      4, // 4
      8 // 8
    ]
  }

  const trafficLineWidth = (trafficThresholds) => {
    return [
      'case',
      // BIK-602: Hide segments with less then 20 traffic
      // but only on traffic maps, not on diff maps or else nothing is shown when there is no diff
      ['<', ['get', 'traffic'], trafficThresholds[0]], // 0
      0.0,
      ['<', ['get', 'traffic'], trafficThresholds[1]], // <200 (or 50, 100)
      0.1,
      ['<', ['get', 'traffic'], trafficThresholds[2]], // <400
      0.2,
      ['<', ['get', 'traffic'], trafficThresholds[3]], // <600
      0.3,
      ['<', ['get', 'traffic'], trafficThresholds[4]], // <800
      0.4,
      ['<', ['get', 'traffic'], trafficThresholds[5]], // <1000
      0.5,
      ['<', ['get', 'traffic'], trafficThresholds[6]], // <1200
      0.6,
      ['<', ['get', 'traffic'], trafficThresholds[7]], // <1400
      0.7,
      ['<', ['get', 'traffic'], trafficThresholds[8]], // <1600
      0.8,
      ['<', ['get', 'traffic'], trafficThresholds[9]], // <1800
      0.9,
      1.0 // If higher, the two lanes e.g. on Augustusbrücke cannot be separated visually
    ]
  }

  const differenceLineWidth = (differenceThresholds) => {
    return [
      'case',
      ['<', ['get', 'traffic'], -differenceThresholds[4]], // -500
      1.2,
      ['<', ['get', 'traffic'], -differenceThresholds[3]], // -400 (-500 ... -400)
      1.0,
      ['<', ['get', 'traffic'], -differenceThresholds[2]], // -300
      0.8,
      ['<', ['get', 'traffic'], -differenceThresholds[1]], // -200
      0.6,
      ['<', ['get', 'traffic'], -differenceThresholds[0]], // -100 (-200 ... -100)
      0.4,
      ['<', ['get', 'traffic'], differenceThresholds[0]], // 100
      0.2,
      ['<', ['get', 'traffic'], differenceThresholds[1] + 1], // 201 (100 ... 200)
      0.4,
      ['<', ['get', 'traffic'], differenceThresholds[2] + 1], // 301
      0.6,
      ['<', ['get', 'traffic'], differenceThresholds[3] + 1], // 401
      0.8,
      ['<', ['get', 'traffic'], differenceThresholds[4] + 1], // 501 (400 ... 500)
      1.0,
      1.2
    ]
  }

  const logoutButton = useMemo(() => <LogoutButton logout={logout} />, [logout])

  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const isTrafficShown = visibleLayerId !== null && visibleLayerId.startsWith(trafficLayerPrefix)
  const isDiffShown = visibleLayerId !== null && visibleLayerId.startsWith(differenceLayerPrefix)
  const isEvaluationShown =
    visibleLayerId !== null && visibleLayerId.startsWith(evaluationLayerPrefix)
  const isScenarioShown =
    visibleLayerId !== null && !isTrafficShown && !isDiffShown && !isEvaluationShown

  return (
    <div>
      {map && (
        <>
          <Header
            mobileView={mobileView}
            right={ logoutButton } />

          <Sidebar bottom={isScenarioShown ? <AttributeSwitcher map={map}/> : null}>
            <ScenarioView
              map={map}
              logout={logout}
              addFeature={addFeature} />

            <TrafficMapsView
              map={map}
              logout={logout}
              addFeature={addFeature} />

            <DifferenceMapsView
              map={map}
              logout={logout}
              addFeature={addFeature} />
          </Sidebar>
        </>
      )}
      {(
        <MapContainer
          map={map}
          setMap={setMap}
          logout={logout}
          mobileView={mobileView}
          accessToken={accessToken}
          trafficThresholds={trafficThresholds}
          differenceThresholds={differenceThresholds}
          evaluationThresholds={evaluationThresholds}
          addFeature={addFeature} />
      )}
      <Footer left={mobileView ? '110px' : 'auto'} right={mobileView ? 'auto' : '270px'} />
    </div>
  )
}

Dashboard.propTypes = {
  accessToken: PropTypes.string.isRequired
}

export default Dashboard

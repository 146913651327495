import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled, LinearProgress, Box } from '@mui/material'
import PropTypes from 'prop-types'
import Dropdown from '../Dropdown'
import ControlButton from '../../ControlButton'
import { setDifferenceColor } from '../../colorRanges.js'
import { asyncRefreshTokenIfRequired, getLocalStorage, getWebsocketApiUrl } from '../../login/utils'
import { Endpoints } from '../../constants/Endpoints'
import { addDifferenceAction, setVisibleLayerIdAction } from '../../../actions/defaultActions'
import { postDifference, getDifference } from '../../DataApi.js'
import { defaultErrorHandling, defaultWebsocketErrorHandling } from '../../ErrorHandlingHelpers'
import { LocalStorage } from '../../constants/LocalStorage.js'
import { Status } from '../../constants/Status'
import { MapLayers } from '../../constants/MapLayers.js'
import { getDiffId, getLayerId } from '../../IdHelper.js'

/**
 * Status code sent to the web socket client after a successful operation completion.
 */
const WEB_SOCKET_STATUS_CODE_FINISHED = 1000

const AddDifferenceMap = ({ map, logout, addFeature }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const differenceMaps = useSelector((state) => state.differenceMaps)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  // Local state
  const [selectedDropdown1LayerObject, setSelectedDropdown1LayerObject] = useState(null)
  const [selectedDropdown2LayerObject, setSelectedDropdown2LayerObject] = useState(null)
  const [progress, setProgress] = useState(null)

  const handleSubmit = async () => {
    // ATTENTION: The customer wants to select the subtrahend first then the minuend
    // Our API expects the mathematical order, i.e.: minuend - subtrahend = diff
    // Thus, in hope to keep the confusion low we just swap both object here.
    const minuend = selectedDropdown2LayerObject
    const subtrahend = selectedDropdown1LayerObject

    const diffId = getDiffId(minuend.scenarioId, subtrahend.scenarioId)
    // The customer expects this order
    const differenceName = subtrahend.name + '; ' + minuend.name
    const minuendId = minuend.scenarioId
    const subtrahendId = subtrahend.scenarioId
    if (differenceMaps.find(element => element.minuendId === minuendId &&
      element.subtrahendId === subtrahendId) !== undefined) {
      alert('Für diese Netz-Kombination existiert bereits eine Differenzkarte.')
      setSelectedDropdown1LayerObject(null)
      setSelectedDropdown2LayerObject(null)
      return
    }

    await difference(minuendId, subtrahendId, diffId, differenceName)

    setSelectedDropdown1LayerObject(null)
    setSelectedDropdown2LayerObject(null)
  }

  const difference = async (minuendId, subtrahendId, diffId, differenceName) => {
    try {
      await postDifference(dispatch, defaultErrorHandling, logout, minuendId, subtrahendId)
      subscribeToProgress(minuendId, subtrahendId, diffId, differenceName)
    } catch (error) {
      defaultErrorHandling(error, logout)
    }
  }

  const subscribeToProgress = async (minuendId, subtrahendId, diffId, differenceName) => {
    // Subscribe to difference progress websocket - TOOD: see cyface app> useSocketManager
    await asyncRefreshTokenIfRequired() // Else no simulation progress [BIK-1283]
    const subProtocols = ['Bearer', getLocalStorage(LocalStorage.AccessToken)]
    const endpoint =
      getWebsocketApiUrl() + Endpoints.DifferenceProgress(minuendId, subtrahendId)
    const socket = new WebSocket(endpoint, subProtocols)

    socket.onmessage = async (event) => {
      const isNumber = Number.isInteger(Number(event.data))
      if (isNumber) {
        const newProgress = parseInt(event.data, 10)
        setProgress(newProgress)
      } else {
        defaultErrorHandling(
          Error('Unrecognized simulation progress message: ' + event.data),
          logout,
          'Simulation fehlgeschlagen'
        )
      }
      if (event.data === '100') {
        setProgress(null) // Reset progress when done
        try {
          // Load differnce
          const difference = (await getDifference(
            dispatch,
            defaultErrorHandling,
            logout,
            minuendId,
            subtrahendId
          )).data
          if (difference.data.status === Status.Running) {
            throw Error('Difference calculation still runnning, no results yet!')
          }
          const differenceData = setDifferenceColor(difference.data.data)

          // Update map
          const layerId = getLayerId(diffId)
          addFeature(map, layerId, differenceData, MapLayers.DifferenceMap)

          // Update redux store
          const differenceMap = {
            id: diffId,
            name: differenceName,
            layerId,
            sourceId: layerId,
            minuendId,
            subtrahendId
          }
          dispatch(addDifferenceAction(differenceMap))

          dispatch(
            setVisibleLayerIdAction(differenceMap.layerId, map, visibleLayerId, roadPropertyStyles)
          )
        } catch (error) {
          defaultErrorHandling(error, logout)
        }
      }
    }
    socket.onerror = (error) => {
      defaultWebsocketErrorHandling(
        error.code ?? 'unbekannt',
        logout,
        'Differenzfortschritt nicht erreichbar. Laden Sie die Seite neu'
      )
    }
    socket.onclose = (event) => {
      if (event.code === WEB_SOCKET_STATUS_CODE_FINISHED) {
        return
      }

      // Handle unexpected closures or errors
      defaultWebsocketErrorHandling(
        event.code ?? 'unbekannt',
        logout,
        'Differenzberechnung nicht erfolgreich. Laden Sie die Seite neu'
      )
    }
  }

  const submitIsDisabled = () => {
    const selection1 = selectedDropdown1LayerObject
    const selection2 = selectedDropdown2LayerObject
    if (selection1 === null || selection2 === null) {
      return true
    } else if (selection1.scenarioId === null || selection2.scenarioId === null) {
      return true
    } else if (selection1.id === selection2.id) {
      return true
    } else {
      return false
    }
  }

  const onDropdown1Change = (layerObject) => {
    setSelectedDropdown1LayerObject(layerObject)
  }

  const onDropdown2Change = (layerObject) => {
    setSelectedDropdown2LayerObject(layerObject)
  }

  return (
    <StyleBox $trafficMaps={trafficMaps}> {/* transient prop */}
      <StyleHead>Differenzkarte berechnen</StyleHead>

      { progress === null && (
        <div>
          <Dropdown
            triggerText='Netz 1 wählen'
            selectedLayerObject={selectedDropdown1LayerObject}
            layerObjects={trafficMaps.map(trafficMap => {
              const layerObject = {}
              layerObject.id = trafficMap.id
              layerObject.name = trafficMap.name
              layerObject.layerId = trafficMap.layerId
              layerObject.scenarioId = trafficMap.scenarioId
              return layerObject
            })}
            onChangeHandler={onDropdown1Change} />

          <Wrapper>
            <Dropdown
              triggerText='Netz 2 wählen'
              selectedLayerObject={selectedDropdown2LayerObject}
              layerObjects={trafficMaps.map(trafficMap => {
                const layerObject = {}
                layerObject.id = trafficMap.id
                layerObject.name = trafficMap.name
                layerObject.layerId = trafficMap.layerId
                layerObject.scenarioId = trafficMap.scenarioId
                return layerObject
              })}
              onChangeHandler={onDropdown2Change} />
          </Wrapper>
        </div>
      )}

      {submitIsDisabled()
        ? ''
        : <Wrapper>
          <ControlButton
            id='submitAddDifference'
            text='Differenz berechnen'
            icon='compare'
            disabled={submitIsDisabled()}
            onClick={handleSubmit}
            sx={{ marginTop: '9px' }} />
          </Wrapper>
      }

      {progress !== null && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </StyleBox>
  )
}

AddDifferenceMap.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

const StyleBox = styled('div')(({ $trafficMaps }) => ({
  border: '1px solid lightgrey',
  width: '94%',
  margin: '10px',
  padding: '10px',
  fontSize: '14pt',
  whiteSpace: 'nowrap',
  display: $trafficMaps.length > 1 ? 'block' : 'none'
}))

const StyleHead = styled('div')({
  fontSize: '15pt',
  color: '#222A35',
  paddingBottom: '10px'
})

const Wrapper = styled('div')({
  paddingTop: '10px'
})

export default AddDifferenceMap

import { createReducer } from '@reduxjs/toolkit'
import {
  setEditModeAction,
  setVisibleLayerIdAction,
  setRoadPropertyStyleActiveAction,
  deleteTrafficAction,
  deleteDifferenceAction,
  addScenarioAction,
  addTrafficAction,
  addDifferenceAction,
  deleteScenarioAction,
  setRoadPropertyStylesAction,
  setMapStyleAction,
  setDevModeAction
} from '../actions/defaultActions'
import RoadProperties, { unchangedWayColor } from '../components/sidebar/scenario/RoadProperties'
import { NetworkColors } from '../components/constants/Colors'
import { getLineSortKey, getLineWidth } from '../components/map/MapBoxHelpers'
import { MapLayers } from '../components/constants/MapLayers'

export const createRoadPropertyStyle = (property) => {
  // Highlight unsaved edits with dashed line
  const lineDashArray = [
    'case',
    ['boolean', ['get', 'edited'], false],
    ['literal', [1, 1]], // dash array for edited features
    ['literal', [1, 0]] // no dash for non-edited features
  ]

  const hasOriginalDependentOpacity = [
    'case',
    ['boolean', ['feature-state', 'selected'], false], 1.0,
    ['boolean', ['feature-state', 'clicked'], false], 1.0,
    ['boolean', ['feature-state', 'hovered'], false], 1.0,
    ['==', ['get', `${property.key}.original`], null], 0.3, // unchanged attribute
    1.0 // Default: 0% transparency if the attribute has been changed
  ]
  // We cannot simply use `= 1.0`, as `relations` style throws an error:
  // When a relation is selected and `exitEditMode` is triggered via `Safe` Button
  /* const hasOriginalIndependentOpacity = [
    'case',
    ['boolean', ['feature-state', 'selected'], false], 1.0,
    ['boolean', ['feature-state', 'clicked'], false], 1.0,
    ['boolean', ['feature-state', 'hovered'], false], 1.0,
    1.0
  ] */
  const routeOpacity = [
    'case',
    ['boolean', ['feature-state', 'selected'], false], 1.0,
    ['boolean', ['feature-state', 'clicked'], false], 1.0,
    ['boolean', ['feature-state', 'hovered'], false], 1.0,
    ['boolean', ['has', property.key], false], 1.0, // 0% transparency for ways with relation
    0.5 // Default: ways without relations should have opacity 0.5 [BIK-1239]
  ]

  const unifiedLineWidth = getLineWidth(false, false)
  const attributeLineWidth = getLineWidth(false, true)

  const unifiedColors = [
    'case',
    ['boolean', ['feature-state', 'clicked'], false], NetworkColors.Clicked,
    ['boolean', ['feature-state', 'hovered'], false], NetworkColors.Hovered,
    NetworkColors.Unified // Default
  ]
  const unifiedEditColors = [
    'case',
    ['boolean', ['feature-state', 'clicked'], false], NetworkColors.Clicked,
    ['boolean', ['feature-state', 'hovered'], false], NetworkColors.Hovered,
    ...property.options.flatMap(option => [
      ['==', ['get', property.key], option.value], option.color
    ]),
    // API only appends `unified` to changed ways, unchanged ways are handles by default
    // not the property.options option for unchanged ways above.
    unchangedWayColor // Default
  ]
  const routeColors = [
    'case',
    // Before clicked to highlight full relation when shared ways with clicked relation
    // Can't use relation0Color: relation ways have different relations[0] then hovered relation
    ['boolean', ['feature-state', 'hovered'], false], NetworkColors.Hovered,
    ['boolean', ['feature-state', 'clicked'], false], NetworkColors.Clicked,
    ['get', 'relationColor'] // Default: relationColor calculated by client
    // Replace the line above with the following to have one color for all relations
    // ['has', property.key], property.options[0].color,
    // NetworkColors.Neutral // Default
  ]
  const propertyDependentColors = [
    'case',
    ['boolean', ['feature-state', 'clicked'], false], NetworkColors.Clicked,
    ['boolean', ['feature-state', 'hovered'], false], NetworkColors.Hovered,
    ...property.options.flatMap(option => [
      ['==', ['get', property.key], option.value], option.color
    ]),
    NetworkColors.Unknown // Default
  ]

  const lineSortKey = getLineSortKey(MapLayers.Scenario, property.key)

  switch (property.key) {
    // Unified style outside edit mode. In edit mode it's defined in the RoadProperties.
    case RoadProperties.unified.key:
      return {
        key: property.key,
        label: property.label,
        active: true, // Default view
        colors: unifiedColors,
        opacity: 1.0,
        // E.g. "green" when the way was added or "yellow" when it was changed
        alternativeColors: unifiedEditColors,
        alternativeOpacity: 1.0,
        lineDashArray,
        lineWidth: unifiedLineWidth,
        lineSortKey
      }
    case RoadProperties.relations.key:
      return {
        key: property.key,
        label: property.label,
        active: null,
        colors: routeColors,
        opacity: routeOpacity,
        alternativeColors: routeColors,
        alternativeOpacity: hasOriginalDependentOpacity,
        lineDashArray,
        lineWidth: attributeLineWidth,
        lineSortKey
      }
    default:
      return {
        key: property.key,
        label: property.label,
        active: null,
        colors: propertyDependentColors,
        opacity: 1.0, // See [BIK-1254] description point C.
        // Colors and opacity for edit mode:
        alternativeColors: propertyDependentColors,
        alternativeOpacity: hasOriginalDependentOpacity,
        lineDashArray,
        lineWidth: attributeLineWidth,
        lineSortKey
      }
  }
}

/**
 * Of the Redux data store.
 *
 * It's *recommended* to only store serializeable stuff (e.g. for time travel).
 */
const initialState = {
  devMode: false, // Hidden mode where the evaluation matrix is shown in the app
  /**
   * Mode which allows the user to change scenario feature properties.
   */
  editMode: {
    active: null,
    scenarioChanged: false, // true if the scenario was changed and simulation needs to be deleted
    scenario: null,
    wayEdit: {
      hoveredWayId: null,
      clickedWayId: null,
      // Describes currently active modification and wraps multi-way selection (CloseWays)
      modification: {
        // The ways marked as selected in the map - to be added or removed
        selectedWayIds: []
      }
    },
    // In the relation edit mode, we also want to be able to select ways to be added/removed
    // from the relation. We store the clicked ways inside the relation object.
    relationEdit: {
      // the objects currently hovered by the mouse in the map
      hovered: {
        relationId: null,
        wayIds: [], // The ways that make up the relation
        tags: {} // The tags of the
      },
      // the objects marked as clicked in the map (i.e. the relation being edited)
      clicked: {
        relationId: null,
        wayIds: [], // The ways that make up the relation
        tags: {} // The tags of the relation
      },
      // Describes what modification is currently being made to the clicked relation
      modification: {
        hoveredWayId: null, // The way currently hovered by the mouse
        // The ways marked as selected in the map - to be added or removed from the relation
        selectedWayIds: []
      }
    }
  },
  /**
   * The layer which is currently shown in the map.
   */
  visibleLayerId: null,
  /**
   * The styles are available for the networkGeometry layers.
   *
   * Attention: The order defines the order in which they are shown on the AttributeSwitched
   * in the sidebar. The current order was requested by [BIK-1238].
   */
  roadPropertyStyles: [
    createRoadPropertyStyle(RoadProperties.unified),
    createRoadPropertyStyle(RoadProperties.roadStyleSimplified),
    createRoadPropertyStyle(RoadProperties.relations),
    createRoadPropertyStyle(RoadProperties.surface),
    createRoadPropertyStyle(RoadProperties.maxSpeed)
  ],
  mapStyle: 'light',
  scenarios: [],
  trafficMaps: [],
  differenceMaps: []
}

const rootReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setDevModeAction, (state, action) => {
      state.devMode = action.payload
    })
    .addCase(setEditModeAction, (state, action) => {
      state.editMode = action.payload
    })
    .addCase(setVisibleLayerIdAction, (state, action) => {
      state.visibleLayerId = action.payload.visibleLayerId
    })
    .addCase(setRoadPropertyStyleActiveAction, (state, action) => {
      const roadPropertyStyles = state.roadPropertyStyles.map(style => {
        if (style.key === action.payload) {
          style.active = true
        } else {
          style.active = false
        }
        return style
      })
      state.roadPropertyStyles = [...roadPropertyStyles]
    })
    .addCase(setRoadPropertyStylesAction, (state, action) => {
      state.roadPropertyStyles = action.payload
    })
    .addCase(setMapStyleAction, (state, action) => {
      state.mapStyle = action.payload
    })
    .addCase(addScenarioAction, (state, action) => {
      const scenario = action.payload
      if (scenario.layerId === null || scenario.layerId === '') {
        throw Error('ADD_SCENARIO: Empty layerId: ' + scenario.layerId)
      }
      state.scenarios = [...state.scenarios, scenario]
    })
    .addCase(addTrafficAction, (state, action) => {
      state.trafficMaps = [...state.trafficMaps, action.payload]
    })
    .addCase(addDifferenceAction, (state, action) => {
      state.differenceMaps = [...state.differenceMaps, action.payload]
    })
    .addCase(deleteScenarioAction, (state, action) => {
      const scenarios = state.scenarios.filter(scenario => {
        return scenario.id !== action.payload
      })
      state.scenarios = [...scenarios]
      state.visibleLayerId = null
    })
    .addCase(deleteTrafficAction, (state, action) => {
      const trafficMaps = state.trafficMaps.filter(trafficMap => {
        return trafficMap.id !== action.payload
      })
      state.trafficMaps = [...trafficMaps]
    })
    .addCase(deleteDifferenceAction, (state, action) => {
      const differenceMaps = state.differenceMaps.filter(differenceMap => {
        return differenceMap.id !== action.payload
      })
      state.differenceMaps = [...differenceMaps]
    })
})

/**
 * Registered reducer which injects actions into Redux.
 *
 * @param {*} state the current state
 * @param {*} action the action to deploy onto the state
 */

// Redux Reducer handles the immutability for us
/*
scenarioReducer = createReducer([] / * init state * /, {
  ADD_SCENARIO: (state, action) => {
    const scenario = action.payload
    state.scenarios.push(scenario)
  }
})
*/

export default rootReducer
